/* Grundläggande reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

main {
  padding: 0px;
}

.omoss {
  margin-bottom: 40px;  /* Skapar utrymme mellan 'Om oss' och 'Produkter' */
  background-color: #f9f9f9;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.omoss h2 {
  font-size: 2.5rem;
  color: #007BFF;
  margin-bottom: 15px;
}

.produktlista {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  padding: 20px;
}

body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  color: #333;
  line-height: 1.6;
  padding: 20px;
}

/* Header-styling */
header {
  background-color: #08192b;
  color: white;
  padding: 20px;
  text-align: center;
  border-radius: 8px;
  margin-bottom: 20px;
}

header h1 {
  font-size: 2rem;
  margin-bottom: 10px;
}

nav ul {
  list-style: none;
  display: flex;
  justify-content: center;
  gap: 20px;
}

nav a {
  text-decoration: none;
  color: white;
  font-weight: bold;
  transition: color 0.3s;
}

nav a:hover {
  color: #FFD700;
}

/* Produktlista */
.produktlista {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  padding: 20px;
}

/* Produktkort */
.produktkort {
  background-color: white;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.produktkort img {
  width: 340px;      /* Sätt en fast bredd för mindre storlek */
  height: auto;      /* Behåll proportionerna */
  border-radius: 8px;
  margin-bottom: 10px;
  object-fit: contain;
  display: block;
  margin-left: auto;  /* Centrerar bilden horisontellt */
  margin-right: auto;
}

.produktkort:hover {
  transform: translateY(-5px);
}

.produktkort h3 {
  color: #007BFF;
  margin-bottom: 10px;
}

.produktkort p {
  margin-bottom: 10px;
}

.produktkort button {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.produktkort button:hover {
  background-color: #218838;
}

.ovrigt-title {
  background-color: #007BFF;  /* Blå bakgrund */
  color: white;               /* Vit text */
  font-size: 2.5rem;          /* Stor text */
  font-weight: bold;          /* Fetstil */
  text-align: center;         /* Centrerad text */
  padding: 40px 0;            /* Tillräckligt med utrymme för att fylla utrymmet */
  border-radius: 8px;         /* Runda hörn som resten av korten */
}

.produktdetalj {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  margin: 20px auto;
  text-align: center;
}

.produktdetalj img {
  width: 200px;
  height: auto;
  border-radius: 8px;
}

.produktdetalj button {
  background-color: #007BFF;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  transition: background-color 0.3s;
}

.produktdetalj button:hover {
  background-color: #0056b3;
}

/* Footer */
footer {
  background-color: #333;
  color: white;
  padding: 20px;
  text-align: center;
  border-radius: 8px;
  margin-top: 20px;
}
.about-contact-container {
  display: flex;
  flex-wrap: wrap;          /* Gör det responsivt */
  gap: 20px;                /* Avstånd mellan kolumnerna */
  justify-content: center;  /* Centrera innehållet */
  padding: 20px;
}

.omoss, .kontakt-info {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
  text-align: center;
}

.kontakt-info h4 {
  color: #007BFF;
  font-size: 1.8rem;
  margin-bottom: 10px;
}

.kontakt-info a {
  color: #007BFF;
  text-decoration: none;
  font-weight: bold;
}

.kontakt-info a:hover {
  text-decoration: underline;
}

.map-container {
  margin-top: 15px;
  border: 2px solid #007BFF;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
  .about-contact-container {
    flex-direction: column;  /* Stapla på mindre skärmar */
    gap: 10px;
  }
}
.omoss h2 {
  font-size: 2.5rem;          /* Gör rubriken större */
  color: #007BFF;             /* Blå färg för att sticka ut */
  margin-bottom: 20px;
}

.omoss .intro-text {
  font-size: 1.5rem;          /* Större introduktionstext */
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.omoss p {
  font-size: 1.2rem;          /* Gör den vanliga texten större */
  color: #555;
  line-height: 1.6;
  margin: 10px 0;
}

.omoss strong {
  color: #FF5722;             /* Orange färg för att dra uppmärksamhet */
  font-size: 1.3rem;
}

.kontakta-btn {
  background-color: #007BFF;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease, transform 0.2s ease;
  margin-top: 15px;
}

.kontakta-btn:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}